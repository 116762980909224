<template>
	<div>
		<cui-error-404 />
	</div>
</template>
<script>
import CuiError404 from '@/components/system/Errors/404'
export default {
	components: {
		CuiError404,
	},
}
</script>
